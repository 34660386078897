<template>
	<div class="faq-section">
		<v-row>
			<v-col>
				<div class="faq-section-header"> FAQ</div>
			</v-col>
		</v-row>
		<v-row>
			<v-expansion-panels class="faq-section-panels">
				<v-expansion-panel class="faq-section-panel">
					<v-expansion-panel-title>
					<!--	<v-icon>mdi-chevron-down</v-icon> -->
						<p class="faq-section-title">{{ $t('message.FAQ.faq1.title') }}</p>
					</v-expansion-panel-title>
					<v-expansion-panel-text style="color:#1c4c34;">
						<p> {{ $t('message.FAQ.faq1.paragraph1') }} </p>
						<p>{{ $t('message.FAQ.faq1.paragraph2') }}</p>
						<p>{{ $t('message.FAQ.faq1.paragraph3') }}</p>
						<p>{{ $t('message.FAQ.faq1.paragraph4') }}</p>
						<p>{{ $t('message.FAQ.faq1.paragraph5') }} </p>				
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>

			<v-expansion-panels class="faq-section-panels">
				<v-expansion-panel class="faq-section-panel">
					<v-expansion-panel-title>
						<p class="faq-section-title">{{ $t('message.FAQ.faq2.title') }}</p>
					</v-expansion-panel-title>
					<v-expansion-panel-text style="color:#1c4c34" >
						{{ $t('message.FAQ.faq2.paragraph1') }}
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>

			<v-expansion-panels class="faq-section-panels">
				<v-expansion-panel class="faq-section-panel">
					<v-expansion-panel-title>
						<p class="faq-section-title">{{ $t('message.FAQ.faq3.title') }}</p>
					</v-expansion-panel-title>
					<v-expansion-panel-text style="color:#1c4c34" >
						{{ $t('message.FAQ.faq3.paragraph1') }}
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>

			<v-expansion-panels class="faq-section-panels">
				<v-expansion-panel class="faq-section-panel">
					<v-expansion-panel-title>
						<p class="faq-section-title">{{ $t('message.FAQ.faq4.title') }}</p>
					</v-expansion-panel-title>
					<v-expansion-panel-text style="color:#1c4c34" >
						{{ $t('message.FAQ.faq4.paragraph1') }}
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-row>	
	</div>
</template>



<style>

	.faq-section {
		padding: 0px 10px 25px 10px;
		background-color:#1c4c34;

		.faq-section-header {
			margin-top:48px;
			font-weight:bold;
			color: #9FCB78;	
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 40px;
			font-weight: 700;
			line-height: 32.4px;
			text-align: left;
		}
		.faq-section-panels {
			@media (min-width: 481px) {
				margin: 25px 100px 25px 100px;
			}
			@media (max-width: 481px) {
				margin: 25px 25px 25px 25px;
				margin-right:25px!important;
			}
		

			.faq-section-panel {
				background-color: white;
				border: 0.6px solid #F3F4FE;
				box-shadow: 0px 11.93px 56.67px 0px #C9CBCC4D;	
				gap: 0px;
				border: 0.6px 0px 0px 0px;
				opacity: 1;
			}
		}
		
		.faq-section-title {
			margin-top: 10px;
			font-weight:bold; 
			color: #1c4c34;
			width: 100%;
			height: 30px;
			top: 25px;
			left: 59.65px;
			opacity: 0.88;
		}
	}
	

</style>


<script>
  export default {
      name: 'FAQSection'
  }
</script>


