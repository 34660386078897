<template>

  <v-container>
    <section class="how-desktop v-container v-container--fluid v-locale--is-ltr pa-4 pa-sm-6 pa-md-8">

      <v-row class="how-section-title-web" >
        <v-col md="12" offset-md="4" class="how-title-web"> {{ $t("message.howItWorks.title1") }} <span style="color: #9fcb78"> {{ $t("message.howItWorks.title2") }} </span> </v-col>
      </v-row>

      <v-row class="how-section-subtitle-web">
        <v-col md="6" offset-md="3">
            {{ $t("message.howItWorks.subtitle") }} 
        </v-col>
      </v-row>

      <v-row>
        <v-col v-for="n in 3"
                :key="n"
                cols="12"
                sm="4">
          <img :src="getImgWeb(n)" :style="getStyleImgWeb(n)" v-bind:alt="n"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-for="n in 2"
                :key="n"
                cols="12"
                offset-md="2"
                sm="2"
                >
          <img :src="getArrowWeb()" style="width:70px; margin-left:70%" v-bind:alt="n"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-for="n in 3"
                :key="n"
                cols="12"
                sm="4" style="font-size:20px;">
              {{getSubtitleWeb(n)}}
        </v-col>
      </v-row>

      <v-row>
        <v-col v-for="n in 3"
                :key="n"
                cols="12"
                sm="4" :style="gestStyleParagraphWeb()">
              {{getParagraphWeb(n)}}
        </v-col>
      </v-row>
  </section>
 


    <section class="how-mobile">

     <v-row class="how-section-title-web" >
        <v-col md="12" offset-md="4" class="how-title-web"> {{ $t("message.howItWorks.title1") }} <span style="color: #9fcb78"> {{ $t("message.howItWorks.title2") }} </span> </v-col>
      </v-row>

      <v-row class="how-section-subtitle-web">
        <v-col md="6" offset-md="3">
            {{ $t("message.howItWorks.subtitle") }} 
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <img src="@/assets/1.png" class="how-stationair-mobile"/>
        </v-col>
        <v-col>
          <p class="how-subtitle-mobile">{{ $t("message.howItWorks.subtitle1") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <img src="@/assets/arrow-down.png" class="how-arrow-right-mobile"/>
        </v-col> 
        <v-col>
          <p class="how-paragraph-mobile">{{ $t("message.howItWorks.paragraph1") }}</p>
        </v-col> 
      </v-row>


      <v-row>
        <v-col>
          <img src="@/assets/2.png" class="how-stationair-mobile"/>
        </v-col>
        <v-col>
          <p class="how-subtitle-mobile">{{ $t("message.howItWorks.subtitle2") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <img src="@/assets/arrow-down.png" class="how-arrow-right-mobile"/>
        </v-col> 
        <v-col>
          <p class="how-paragraph-mobile">{{ $t("message.howItWorks.paragraph2") }}</p>
        </v-col> 
      </v-row>     

      <v-row>
        <v-col>
          <img src="@/assets/1.png" class="how-stationair-mobile"/>
        </v-col>
        <v-col>
          <p class="how-subtitle-mobile">{{ $t("message.howItWorks.subtitle3") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
         
        </v-col> 
        <v-col>
          <p class="how-paragraph-mobile">{{ $t("message.howItWorks.paragraph3") }}</p>
        </v-col> 
      </v-row>


  </section>
  
 </v-container>

</template>

<script>


export default {
  name: 'HowItWorksView',
  methods: {
    goToHome () {
      this.$router.push('/')
    },

    getImgWeb(n) {
      var images = require.context('../assets/', false, /\.png$/);
      return images('./' + n + ".png");
    },
    getSubtitleWeb(n) {
      switch(n) {
        case 1: return this.$t("message.howItWorks.subtitle1");
        case 2: return this.$t("message.howItWorks.subtitle2");
        case 3: return this.$t("message.howItWorks.subtitle3");
      }
    },
    getParagraphWeb(n) {
      switch(n) {
        case 1: return this.$t("message.howItWorks.paragraph1");
        case 2: return this.$t("message.howItWorks.paragraph2");
        case 3: return this.$t("message.howItWorks.paragraph3");
      }
    },
    getArrowWeb() {
      var images = require.context('../assets/', false, /\.png$/);
      return images('./arrow-right' +  ".png");
      //return images('./arrow-right.png");
    },

    //style
    getStyleImgWeb(n) {
      var style ='padding-left:50px;';
      switch(n) {
        case 1: return style+'width:300px';
        case 2: return style+'width:230px';
        case 3: return style+'width:300px';
        default: return style+'width:300px';
      }
    },
    gestStyleParagraphWeb() {
      return 'padding-right:100px;'
    }
 
  }
}
</script>

<style>


  .how-section-title-web{
    margin-bottom: -50px;
    flex-direction: column;
    align-items: center;

    .how-title-web{
      font-weight: bold;
      font-size: 40px;

      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  .how-section-subtitle-web {
    margin-bottom: 50px;
    font-size: 16px;
  }

  
  @media screen and (max-width: 600px) {
    .how-desktop {
      display:none;
    }
  }


  @media screen and (min-width: 600px) {
    .how-mobile {
      display:none;
    }
  }

  .how-section-subtitle {
    margin-bottom: 50px;
    font-size: 16px;
  }

  .how-subtitle-mobile {
    font-size: 20px;
  }

  .how-paragraph-mobile {
    font-size: 16px;
  }

  .how-stationair-mobile{
    width:110px;
  }

  .how-baggy-mobile{
    width:90px;
  }

  .how-arrow-right-mobile{
    width:35px;
    margin-top: 20px;
    margin-left: 30px;
  }

  .how-subtitle{
      font-size:15px;
      padding-top: 30px;
    }  


@media screen and (max-width: 600px) {
  img {
    width:300px;
  }
}


@media screen and (min-width: 600px) {
  img {
    width:500px;
  }
}





</style>