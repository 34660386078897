<template>
  <div class="calendar-template">
    <!-- Display days -->
    <h3>{{ localTitolo.value }}</h3>
    <div class="days-container">
      <button @click="prevDays" class="nav-button">◀</button>
      <div
        v-for="(day, index) in visibleDays"
        :key="index"
        :class="{
          'day-pc': screenWidth >= 550,
          'day-mobile': screenWidth < 550,
          selected: isSelected(day),
        }"
        @click="selectDay(day)"
      >
        <!-- <div>{{ day.format('ddd') }}</div> -->
        <p>{{ day.format("DD MMM") }}</p>
      </div>
      <button @click="nextDays" class="nav-button">▶</button>
    </div>

    <!-- Display time slots -->
    <div class="slots-box">
      <button @click="prevTimeSlot" class="nav-button">◀</button>
      <div class="slider-container">
        <div class="time-slot" v-if="!isNextTimeSlot">
          <p
            v-for="(slot, index) in timeSlot1"
            :key="index"
            class="time-slot-button"
            :class="{ 'selected-time': selectedTime === slot }"
            @click="selectTime(slot)"
          >
            {{ slot }}
          </p>
        </div>
        <div class="time-slot" v-if="isNextTimeSlot">
          <p
            v-for="(slot, index) in timeSlot2"
            :key="index"
            class="time-slot-button"
            :class="{ 'selected-time': selectedTime === slot }"
            @click="selectTime(slot)"
          >
            {{ slot }}
          </p>
        </div>
      </div>
      <button @click="nextTmeSlot" class="nav-button">▶</button>
    </div>

    <button
      class="select-button"
      @click="confirmSelection"
      :disabled="!isSelectedDropTime"
    >
      Select
    </button>
  </div>
</template>

<script>
import moment from "moment";
import { computed } from "vue";

export default {
  name: "calendarComponent",
  props: {
    pickupDateTime: {
      type: String,
    },
    dropoffDateTime: {
      type: Object,
    },
    titolo: {
      type: String,
    },
  },

  setup(props) {
    const localTitolo = computed(() => {
      const value = props.titolo;
      return {
        value: value,
      };
    });

    const localPickup = computed(() => {
      const date = moment(props.pickupDateTime, "DD MMM YYYY, HH:mm");
      return {
        isValid: date.isValid(),
        date: date,
      };
    });

    const localDropoff = computed(() => {
      if (!props.dropoffDateTime) return null;
      const date = moment(props.dropoffDateTime, "DD MMM YYYY, HH:mm");
      return {
        isValid: date.isValid(),
        date: date,
      };
    });

    return {
      localTitolo,
      localPickup,
      localDropoff,
    };
  },

  data() {
    return {
      selectedDay: null,
      selectedTime: null,
      days: [],
      startIndex: 0,
      timeSlot1: [],
      timeSlot2: [],
      timeSlotDefault1: [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
      ],
      timeSlotDefault2: [
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
      ],
      timeSlotPass1: [],
      timeSlotPass2: [],
      isNextTimeSlot: null,
      isTimeSlotEmpty: null,
      isSelectedDropTime: null,
      screenWidth: window.innerWidth,
      numberDaysVisible: 0,
    };
  },
  computed: {
    visibleDays() {
      // Show only 5 days starting from startIndex

      if (this.screenWidth < 550) {
        return this.days.slice(this.startIndex, this.startIndex + 4);
      } else {
        return this.days.slice(this.startIndex, this.startIndex + 5);
      }
    },
    visibleTimeSlots() {
      return this.timeSlot1.slice(0, 5);
    },
  },
  methods: {
    initializeDays() {
      let today = moment();

      if (this.localPickup.date.isValid()) {
        today = this.localPickup.date;
      }
      for (let i = 0; i < 200; i++) {
        this.days.push(moment(today).add(i, "days"));
      }
      this.selectedDay = this.days[0]; // Pre-select today's date
    },

    inizializeTimeSlots() {
      // Check if dropoff date is set and it's after pickup date
      if (this.localPickup.date.isValid()) {
        const timeLocalPickup = this.localPickup.date.format("HH:mm");
        const end0 = moment("13:30", "HH:mm");
        const start = moment(timeLocalPickup, "HH:mm").add(30, "minutes");

        // Crea i time slots incrementando di mezz'ora
        for (
          let m = moment(start);
          m.isSameOrBefore(end0);
          m.add(30, "minutes")
        ) {
          this.timeSlot1.push(m.format("HH:mm")); // Aggiunge l'orario formattato
        }

        this.timeSlotPass1 = this.timeSlot1; // memorizzo l'attuale time slots in una variabile che mi serve nell'altra componente calendar

        /* inizializzo timeSlot2 */

        const end1 = moment("18:30", "HH:mm");

        for (
          let m = moment(end0).add(30, "minutes");
          m.isSameOrBefore(end1);
          m.add(30, "minutes")
        ) {
          this.timeSlot2.push(m.format("HH:mm")); // Aggiunge l'orario formattato
        }

        this.timeSlotPass2 = this.timeSlot2;
      }
      if (this.localPickup.isValid == false) { // Se la data di partenza non è valida
        let now = moment(); // Ottieni l'ora corrente
        let minutes = now.minutes(); // Ottieni i minuti correnti

        // Arrotonda al prossimo intervallo di mezz'ora
        let roundedMinutes = Math.ceil(minutes / 30) * 30;
        if (roundedMinutes === 60) {
          now.add(1, "hours"); // Se arrotondi a 60, aggiungi un'ora
          roundedMinutes = 0; // E imposta i minuti a 00
        }

        now.minutes(roundedMinutes).seconds(0); // Imposta i minuti arrotondati, azzera i secondi

        // Aggiungi 30 minuti all'ora arrotondata
        const start = now.add(30, "minutes");/* moment("09:30", "HH:mm"); */

        const end0 = moment(start).add(11 * 30, "minutes");

        if (start < end0) {
          let i = -1 ; 
          for (
            let m = moment(start);
            m.isSameOrBefore(end0);
            m.add(30, "minutes")
          ) {
            i = i++;

            this.timeSlot1.push(m.format("HH:mm")); // Aggiunge l'orario formattato
            this.timeSlotPass1 = this.timeSlot1; // memorizzo l'attuale time slots in una variabile che mi serve nell'altra componente calendar
          }
        } else {
          // Se l'orario di partenza è dopo quello di arrivo

          this.isNextTimeSlot = true;
          this.isTimeSlotEmpty = true;
          this.timeSlot2 = this.timeSlotDefault2;
          this.timeSlotPass2 = this.timeSlotDefault2;
        }

        /* inizializzo timeSlot2 */
        const end1 = moment("18:30", "HH:mm");
        for (
          let m = moment(end0).add(30, "minutes");
          m.isSameOrBefore(end1);
          m.add(30, "minutes")
        ) {
          this.timeSlot2.push(m.format("HH:mm")); // Aggiunge l'orario formattato
        }

        if(this.timeSlot2.length === 0){ // se timeSlot2 è 0 allora timeSlot2 viene ripristinato con i timeSlot1
          this.timeSlot2 = this.timeSlot1;
        }

        this.timeSlotPass2 = this.timeSlot2;

     }
    },
    isSelected(day) {
      return day.isSame(this.selectedDay, "day");
    },
    selectDay(day) {
      this.selectedDay = day;
      this.selectedTime = null;

      if (day.isSame(moment(), "day")) {
        this.timeSlot1 = this.timeSlotPass1;
        this.timeSlot2 = this.timeSlotPass2;

        console.log(this.timeSlot1)

        if(this.timeSlot2.length == 0){
          this.timeSlot2 = this.timeSlotPass1;
        }

        /* this.isNextTimeSlot = true;
        this.isTimeSlotEmpty = true; */
        console.log("Selected day is today");
      } else {
        this.isTimeSlotEmpty = false;
        this.timeSlot1 = this.timeSlotDefault1;
        this.timeSlot2 = this.timeSlotDefault2;
        this.isNextTimeSlot = false;
      }
    },
    selectTime(slot) {
      this.selectedTime = slot;
    },
    prevDays() {
      if (this.startIndex > 0) {
        this.startIndex -= this.numberDaysVisible;
      }
    },
    nextDays() {
      if (this.startIndex < this.days.length - 5) {
        this.startIndex += this.numberDaysVisible; //
      }
    },
    nextTmeSlot() {
      if (!this.isNextTimeSlot) {
        this.isNextTimeSlot = true;
      }
    },
    prevTimeSlot() {
      if (this.isNextTimeSlot) {
        this.isNextTimeSlot = false;
      }
    },
    confirmSelection() {
      if (this.selectedDay && this.selectedTime) {
        const dateTime = `${this.selectedDay.format("DD MMM YYYY")}, ${
          this.selectedTime
        }`;
        this.$emit("date-time", dateTime); // Emit selected date and time to parent component
        this.$emit("is-open", false);
      } else {
        alert("Please select a day and time");
      }
    },

    checkSelection() {
      // Check if selected day and time are valid
      if (this.selectedTime && this.isSelected(this.selectedDay)) {
        this.isSelectedDropTime = true;
      } else {
        this.isSelectedDropTime = false;
      }
    },

    updateScreenWidth() {
      this.screenWidth = window.innerWidth; // Aggiorna la variabile con la nuova larghezza
      if (this.screenWidth <= 550) {
        this.numberDaysVisible = 4;
      } else {
        this.numberDaysVisible = 5;
      }
    },
  },
  watch: {
    selectedTime() {
      this.checkSelection();
    },
    selectedDay() {
      this.checkSelection();
    },
    screenWidth(newWidth) {
      if (newWidth <= 550) {
        this.numberDaysVisible = 4;
      } else {
        this.numberDaysVisible = 5;
      }
      // Puoi eseguire altre logiche qui, ad esempio aggiornare il layout o altro
    },
  },
  mounted() {
    this.initializeDays();

    this.inizializeTimeSlots();

    this.updateScreenWidth(); // Call updateScreenWidth on window load and on window resize event to handle responsive design properly.

    window.addEventListener("resize", this.updateScreenWidth); // Update screen width on window resize
  },
};
</script>

<style scoped>
h3 {
  color: #1c4c34;
  width: 100%;
  text-align: center;
  padding: 5px;
  font-size: 18px;
}

h3::first-letter {
  text-transform: uppercase;
}
.calendar-template {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 20px;
  padding: 30px;

  border-radius: 8px;
  background-color: white;
}

.days-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 0;
}

.day-pc,
.day-mobile {
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 50%;
  background-color: #ecf5e4;
}

.day-pc p {
  text-align: center;
  padding: 10px;
  font-size: 16px;
}

.day-mobile p {
  text-align: center;
  padding: 10px;
  font-size: 22px;
}

.day-pc.selected,
.day-mobile.selected {
  background-color: #1c4c34;
  color: white;
  font-family: Wotfard-SemiBold;
}

.nav-button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.nav-button.disabled {
  color: white;
  cursor: unset;
}

.slots-box {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5%;
}

.slider-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;

  padding: 2px;
  gap: 10px;
}

.time-slot {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

.time-slot-button {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  background-color: #ecf5e4;
  font-size: 16px;
}

.selected-time {
  background-color: #1c4c34;
  color: white;
  font-family: Wotfard-SemiBold;
}

.select-button {
  width: 90%;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #1c4c34;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Wotfard-SemiBold;
}

.select-button:disabled {
  background-color: #92b7a5;
  cursor: unset;
}

@media (max-width: 700px) {
  .day-pc {
    font-size: 1.1rem;
  }

  .time-slot {
    width: 100%;
  }

  .select-button {
    width: 100%;
  }
}

@media (min-width: 380px) and (max-width: 500px) {
  .day-mobile p {
    font-size: 18px;
  }

  .time-slot-button {
    font-size: 10px;
  }

  .time-slot {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
}

@media (max-width: 379px) {
  .day-mobile p {
    font-size: 14px;
  }

  .nav-button {
    font-size: 8px;
  }

  .time-slot {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }

  .time-slot-button {
    font-size: 10px;
  }
}
</style>
