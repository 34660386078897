<template>
  <v-container class="baggy-pages" >
    <section class="v-container v-container--fluid v-locale--is-ltr pa-4 pa-sm-6 pa-md-8">
     
      <v-row class="become-partner-section-title" >
        <div class="become-partner-title"> {{ $t("message.becomePartner.title1") }} <span style="color: #9fcb78"> {{ $t("message.becomePartner.title2") }} </span> </div>
      </v-row>

    <v-row>
      <v-col-1>
        <img src="@/assets/star.png" class="become-img"/>
      </v-col-1>
      <v-col>
        <strong>{{ $t('message.becomePartner.subtitle1') }}</strong>
      </v-col>
    </v-row>

        &nbsp;
    <v-row>
      <v-col-1>
        <img src="@/assets/cup.png" class="become-img"/>
      </v-col-1>
      <v-col>
        <strong>{{ $t('message.becomePartner.subtitle2') }}</strong>
      </v-col>
    </v-row>

         &nbsp;
    <v-row>
      <v-col-1>
        <img src="@/assets/smile.png" class="become-img"/>
      </v-col-1>
      <v-col>
        <strong>{{ $t('message.becomePartner.subtitle3') }}</strong>
      </v-col>
    </v-row>

         &nbsp;
   <v-row>
      <v-col-1>
        <img src="@/assets/done.png" class="become-img"/>
      </v-col-1>
      <v-col>
        <strong>{{ $t('message.becomePartner.subtitle4') }}</strong>
      </v-col>
    </v-row>
         &nbsp;

    <v-row>
      <v-col>
      <!--
        <v-btn id="Message" class="become-button" @click="goto()" color="#1c4c34"> 
        {{$t('message.becomePartner.joinus')}}
        </v-btn>
        -->


          <v-dialog max-width="500">
            <template v-slot:activator="{ props: activatorProps }">
              <v-btn class="become-button" style="background-color:#1c4c34; color:white;"
                v-bind="activatorProps"
                variant="flat"
              >{{$t('message.becomePartner.joinus')}}</v-btn>
            </template>

            <template v-slot:default="{ isActive }">
              <v-card title="">
                <v-card-text>
                  infocontact@baggysitter.com
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                  
                    @click="isActive.value = false"
                  >
                  {{$t('message.becomePartner.close')}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>   
          
      </v-col>
    </v-row>

    <v-row style="text-align:center; margin-top:15px">
      <v-col>
        <img src="@/assets/milanbag.png" />
      </v-col>
    </v-row>



    </section>
  </v-container>
</template>
<script>

export default {
  name: 'BecomePartnerView',
  methods: {
    goToHome () {
      this.$router.push('/')
    },
    goto() {
      window.location.href="mailto:mail@example.com=Join us&body=Test";
    }
  }
}
</script>

<style>


.become-partner-section-title{
  flex-direction: column;
  align-items: center;

  .become-partner-title{
    font-weight: bold;
    font-size: 40px;

    margin-top: 50px;
    margin-bottom: 50px;

  }
}

.become-img{
  width:20px; margin-top:15px;  
}


  .become-button {
    --display:block;
   
    @media screen and (min-width: 480px) {
      width:240px;
    }
    @media screen and (max-width: 600px) {
      width:100%;
    }
  }


</style>