<template>
  <div class="baggy-pages" >
    <div class="grid-section-home" >
      <div class="first-row">
        <img src="@/assets/logo-introsection.png" alt="" />

        <div class="component-intro-section">
          <IntroSection></IntroSection>
        </div>

        <div class="book-component">
          <BookingSection> </BookingSection>
        </div>
      </div>

      <div class="second-row">
        <OurValuesSection> </OurValuesSection>
      </div>
    </div>

    <div class="baggy-bottom-section">
      <v-row class="how-it-works">
        <a id="how-it-works" />
        <HowItWorksSection> </HowItWorksSection>
      </v-row>

      <v-row class="become-partner">
        <a id="become-partner" />
        <BecomePartnerSection> </BecomePartnerSection>
      </v-row>
    </div>
  </div>
  <v-row class="faq">
    <a id="faq" />
    <v-col no-gutters lg="12" md="12" sm="12">
      <FAQSection> </FAQSection>
    </v-col>
  </v-row>
</template>

<script>
import IntroSection from "@/components/IntroSection";
import BookingSection from "@/components/BookingSection";
import OurValuesSection from "@/components/OurValuesSection";
import HowItWorksSection from "@/components/HowItWorksSection";
import BecomePartnerSection from "@/components/BecomePartnerSection";
import FAQSection from "@/components/FAQSection";

export default {
  name: "HomeView",
  components: {
    IntroSection,
    BookingSection,
    OurValuesSection,
    HowItWorksSection,
    BecomePartnerSection,
    FAQSection,
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      console.log(top);
      window.scrollTo(0, top);
    },
  },
};
</script>

<style scoped>
.baggy-pages{
  margin-top: 5%;
  overflow: hidden;
}
.grid-section-home {
  display: flex;
  flex-direction: column;
}

.first-row {
  position: relative;
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 80vh;

}

.first-row img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60%;
  z-index: 0;
}

.component-intro-section {
  flex: 60%;
  align-items: center;
  margin-bottom: 10%;
}

.book-component {
  flex: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding-bottom: 10%;
  z-index: 1;
  
  width: 100%;
}

.our-value-section {
  grid-column: 1 / span 2; /* Occupa entrambe le colonne */
  grid-row: 2; /* Seconda riga */
}
.baggy-button {
  color: #1c4c34;
  width: 100%;
}

#logo-img {
  width: 100px;
}

.left-section {
  color: #1c4c34;
}

.v-banner {
  position: static;
  font-size: 20px;
  padding: 0;
}

@media (max-width: 1551px) {
  .book-component{
    margin-right: 5%;
  }
  
}

@media (min-width: 1350px) and (max-width: 1550px){
  .book-component{
    margin-right: 10%;
  }
  
}
@media (max-width: 1000px) {
  .first-row {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    height: 110vh;
    background-color: #9fcb78;

  }
  .first-row img {
    display: none;
  }

  .component-intro-section {

    width: 100%;
    position: relative;
    z-index: 0;

    margin: 0 0 -30px 0;
    background-color:#1c4c34;

  }

  .book-component{
    position: relative;
    z-index: 1;
    background-color: #9fcb78;

    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
  }
  .grid-section-home {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .baggy-discount {
    font-size: 15px;
  }
  .baggy-bottom-section {
    padding: 15px;
  }
}

@media screen and (min-width: 480px) {
  .v-banner-resize {
    padding-left: 200px;
  }
}

@media screen and (min-width: 600px) {
  .v-banner-resize {
    padding-left: 200px;
  }
}

.faq {
  justify-content: left;
  background-color: #1c4c34;
  margin: 0px;
  overflow-y: scroll;
}

.left-section .bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  --background: url("~@/assets/bagagli.jpg");
  background-size: cover;
  opacity: 0.2;
  width: 100%;
  height: 100%;
}

.left-section p {
  text-align: left;
}
</style>
