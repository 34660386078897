<template>
  <div class="intro-section-template">
    <div class="div-row">
      <h1>{{ $t("message.introSection.titleb") }} &nbsp;</h1>
      <h1 style="color: #9FCB78;">{{ $t("message.introSection.titlec") }}</h1>
    </div>

    <div class="div-row">
      <h1>{{ $t("message.introSection.titled") }}</h1>
    </div>

    <div class="div-row">
      <h1>{{ $t("message.introSection.titlee") }} &nbsp;</h1>
      <h1 style="color: #9FCB78;">{{ $t("message.introSection.titlef") }}</h1>
    </div>

    <div class="div-row">
      <p>
      {{ $t("message.introSection.title") }}
    </p>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "IntroSection",
  methods: {
    goto(refName) {
      var element = document.getElementById(refName).offsetTop - 80;
      window.scrollTo(0, element);
    },
  },
};
</script>

<style>

h1{
  font-family: Wotfard-Bold;
  padding: 0;
  font-size: 55px;
}

p{
  width: 100%;
  text-align: start;
  font-size: 26px;

  font-family: wotfard-regular-webfont;
  padding: 10px 0 0 0;
}

.intro-section-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  padding: 10px 0 0 0;

}

.div-row {
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 80%;
  z-index: 1;
  margin: 0;
}

@media (min-width:900px) and (max-width: 1000px) {

  .intro-section-template{
    justify-content: start;
    padding-top: 40px;
  }
  .intro-section-template h1{
    color: white;
    font-size: 2.7rem;
  }
  .intro-section-template p{
    color: white;
  }
}

@media (max-width:899px){
  .intro-section-template{
    justify-content: start;
    padding-top: 40px;
  }
  .intro-section-template h1{
    color: white;
    font-size: 2rem;
  }
  .intro-section-template p{
    color: white;
  }
}
@media (min-width: 601px) and (max-width: 1270px) {
  h1{
    font-size: 43px;
  }
  p{
    font-size: 24px;
  }
  
}
@media (min-width: 421px) and (max-width: 600px) {
  h1{
    font-size: 29px;
  }
  p{
    font-size: 22px;
  }
}

@media (min-width: 200px) and (max-width: 419px) {
  h1{
    font-size: 2vw;
  }
  p{
    font-size: 4.64vw;
  }
}

</style>
