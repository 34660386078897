<template>
  <div class="custom-number-input">
    <button class="button-decrement" @click="updateValue(-1)"><p>-</p></button>
    <input
      ref="inputRef"
      class="number"
      type="number"
      min="0"
      max="10"
      value="0"
    />
    <button class="button-increment" @click="updateValue(1)"><p>+</p></button>
  </div>
</template>

<script>

import { ref,watch } from "vue";

export default {
  name: "CustomBagsInput",

  setup(props, { emit }) {
    const inputRef = ref(null); // Riferimento all'input
    const currentValue = ref(0); // Stato locale per il valore corrente

    const updateValue = (change) => {
      let value = currentValue.value + change;
      if (value >= 0 && value <= 10) {
        currentValue.value = value;
        emit("number", currentValue.value); // Emissione dell'evento
      }
    };

    // Mantieni sincronizzato il valore nell'input con currentValue
    watch(currentValue, (newValue) => {
      if (inputRef.value) {
        inputRef.value.value = newValue;
      }
    });

    return {
        inputRef,
        currentValue,

      updateValue,
    };
  },
};
</script>

<style scoped>
p {
  font-size: 24px;

  text-align: center;
  margin-bottom: 2px;
  padding: 0;
}

.custom-number-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 2px;
  width: 70%;

  background-color: inherit;
}

.button-increment {
  width: 33%;
  height: 30px;
  background-color: #ddedd0;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.button-decrement {
  width: 33%;
  height: 30px;
  background-color: #d9d9d966;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;
}

.number {
  text-align: center;
}

/* Rimuove le frecce su Chrome, Safari, Edge e Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Rimuove le frecce su Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */

@media(min-width:801px){
    .custom-number-input{
        width: 25%;
    } 
    
}

@media (max-width:800px) {
    .custom-number-input{
        width: 40%;
    } 
}

@media (min-width:480px) {
    .custom-number-input{
        width: 50%;
    } 
}
</style>
